@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.poppins-thin {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.poppins-extralight {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.poppins-extrabold {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.poppins-black {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.poppins-thin-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.poppins-extralight-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.poppins-light-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.poppins-regular-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.poppins-medium-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.poppins-semibold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.poppins-bold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.poppins-extrabold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: italic;
}

.poppins-black-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: italic;
}

*{
  margin: 0;
  padding: 0;
}

a{
  text-decoration: none;
}



.main{
  width: 100%;
  height: 100vh;
  background-image: url(Assets/beautiful-african-agriculturist-farm-using-laptop-scaled.jpeg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-bottom: 10vh;
  position: relative;
  /* overflow-y: scroll; */
}

.dk{
  width: 100%;
  position: absolute;
  top: 0;
  height: 100%;
  left: 0;
  background-color: hsla(0, 0%, 0%,.6);
  z-index: 2;
}

header{
  width: 90%;
  margin: auto;
  height: 20vh;
  display: flex;
  align-items: center;
  z-index: 6;
  position: relative;
}

header img{
  width: 13%;
  height: 58%;
  object-fit: contain;
}

.main .main-c{
  width: 80%;
  margin: auto;
  margin-top: 50px;
  text-align: center;
  z-index: 7;
  position: relative;
}

.main .main-c h1{
  font-size: 50px;
  color: #fff;
  letter-spacing: 1px;
}

.main .main-c h3{
  font-size: 28px;
  color: #FD721B;
  margin-bottom: 10px;
}

.main .main-c p{
  font-size: 20px;
  color: #fff;
  margin-bottom: 10px;
}

.main .main-c .countdown{
  width: 100%;
  margin-top: 40px;
  display: flex;
  justify-content: space-between;

}

.main .main-c .countdown div{
  width: 22%;
  text-align: center;
}
.main .main-c .countdown div h2{
  background-color: hsla(0, 0%, 100%,.2);
  padding: 30px 0;
  color: #fff;
  font-size: 40px;
}

.main .main-c .countdown div h5{
  background-color: #fff;
  padding: 13px 0;
  color: #000;
  font-size: 16px;
  font-weight: 400;
}

.main .main-c h6{
  color: #fff;
  font-size: 22px;
  margin-top: 40px;
}

.main .main-c .form{
  width: 55%;
  margin: auto;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.main .main-c .form p{
  width: 100%;
  text-align: start;
}

.main .main-c .form input{
  width: 65%;
  height: 50px;
  outline: none;
  border: none;
  background-color: #fff;
  padding-left: 10px;
  border-radius: 5px;
}

.main .main-c .form button{
  width: 30%;
  background-color: #FD721B;
  color: #fff;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  height: 50px;
  font-weight: 700;
  cursor: pointer;
}


@media screen and (max-width: 750px) {
  header img{
    width: 20%;
  }
  .main .main-c .form{
    width: 70%;
  }
  .main .main-c h1{
    font-size: 40px;
  }
}

@media screen and (max-width: 600px){
  header img{
    width: 30%;
  }
  
  .main .main-c{
    width: 90%;
  }
  .main .main-c .countdown div{
    width: 24%;
  }
  .main .main-c .form{
    width: 90%;
  }
}

@media screen and (max-width: 430px){
  .main .main-c h1{
    font-size: 40px;
  }
  .main .main-c h3{
    font-size: 21px;
  }
  .main .main-c p{
    font-size: 16px;
  }
  .main .main-c h6{
    font-size: 17px;
  }
  .main .main-c .form{
    width: 100%;
  }
  .main .main-c h1{
    font-size: 30px;
  }
}
@media screen and (max-width: 400px){
  header img{
    width: 40%;
  }
  .main{
    padding-bottom: 60px;
  }
  .main .main-c .countdown{
    flex-wrap: wrap;
  }
  .main .main-c .countdown div{
    width: 47%;
    margin-bottom: 14px;
  }
  .main{
    height: auto;
  }
}



@media screen and (max-width: 360px){
  .main .main-c h1{
    font-size: 36px;
  }
  .main .main-c .form input{
    width: 100%;
    margin-bottom: 20px;
  }
 
}

@media screen and (max-width: 330px){
  .main .main-c h1{
    font-size: 30px;
  }
  .main .main-c .form button{
    font-size: 14px;
  }
}